.background {
    background-image: url('../../images/GamesBackground.webp');
    background-position: center center;
    background-color: #464646;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100%;
    display:grid;
}

.imageContainer{
    align-self: center;
    justify-self: center;
    position: absolute;
    top: 35%;
    background-color:rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    width: 20%;
}
.image{
    width: 100%;
    height: 100%;
}
.image:hover {
    cursor: pointer;
}

@media (max-width: 1200px){
    .imageContainer{
        width: 35%;
    }
}

@media (max-width: 600px) {
    .imageContainer {
        width:50%;    
    }
}