h1, h2, h3, h4 {
    margin: 0px;
}

p {
    margin-bottom: 0px;
}

.header {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(11, 12, 12, 0.574);
    margin-bottom: 20px;
}

.container{
    display:grid;
    grid-gap: 5px;
    margin-right: 20%;
    margin-left: 20%;
    margin-bottom: 1%;
    grid-template-rows: 10fr 8fr;
    height: 100%;
    background-color: #151515ef;
    border-radius: 20px;
    border: 4px solid var(--border-color);
}

.platforms {
    display: flex;
    justify-content: left;
}

.li {
    display: inline-block;
    padding-left: 20px;
    border: 10px red;
    border-radius: 10px;
}

.imageContainer{
    height: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.infoContainer{
    height: 100%;
    padding: 10px;
    padding-top: 0px;
    display:grid;
    grid-template-rows: 4fr 1fr;
    grid-gap: 10px;
}

.descriptionContainer {
    background-color: #151515ef;
    border-radius: 20px;
    padding-left:20px;
    padding-right:20px;
}

.descriptionContainer h4 {
    text-align: left;

}

.moreInfoContainer{
    text-align: left;
    background-color: #151515ef;
    border-radius: 20px;
    display:flex;
    flex-direction: column;
    justify-items: left;
    padding: 10px;
    padding-left:20px;
    padding-right:20px;
}

.loading {
    align-self: center;
    justify-self: center;
    width: 20%;
    margin-top: 150px;
}
@media (max-width: 1800px) {
    .container{
        margin: 15%;
        margin-top: 0px;
        margin-bottom: 1%;
    }
}
@media (max-width: 1400px) {
    .container{
        margin: 10%;
        margin-top: 0px;
        margin-bottom: 1%;
    }
}
@media (max-width: 900px) {
    .container{
        margin: 3%;
        margin-top: 0px;
        margin-bottom: 1%;
        grid-template-rows: 4fr 8fr;
    }
    .infoContainer{
        grid-template-rows: 6fr 1fr;
    }
}
@media (max-width: 600px) {
    .container{
        margin: 3%;
        margin-top: 0px;
        margin-bottom: 1%;
        grid-template-rows: 3fr 8fr;
    }
}
@media (max-width: 400px) {
    .container{
        margin: 3%;
        margin-top: 0px;
        margin-bottom: 1%;
        grid-template-rows: 2fr 8fr;
    }
}