.headerWrapper {
    display: grid;
    background-color: rgba(11, 12, 12, 0.574);
    height: 130px;
    max-height: 130px;
    grid-template-columns: 1fr 1fr;
}

.headerWrapper h1{
    color: aqua;
    margin: 0px;
    font-size: 72px;
    padding-left: 25px;
}

.text{
    align-self: center;
    text-align: left;
}

.filtersContainer{
    display:grid;
}

.filtersContainer h2{
    margin: 0px;
}

.filter {
    margin: 0px;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    text-align: start;
}

.sort{
    margin: 0px;
    display:grid;
    grid-template-columns: 50% 1fr 1fr;
    align-items: center;
    text-align: left;
}

.span{
    padding-left: 6px;
    color: rgba(6, 207, 36, 0.908)
}

.button{
    color: rgb(255, 0, 0);
    width: 100%;
}
.button:hover{
    cursor: pointer;
    color: rgba(255, 0, 0, 0.445);
}

.ascendToDescend{
    color: blueviolet;
    font-size: 20px;
}
.ascendToDescend:hover{
    cursor: pointer;
    color: rgba(137, 43, 226, 0.719);
}

@media (max-width: 1200px){
    .headerWrapper h1{
        color: aqua;
        margin: 0px;
        font-size: 40px;
    }
    .filtersContainer h2{
        font-size: 15px;
    }
}
@media (max-width: 900px){
    .headerWrapper h1{
        color: aqua;
        margin: 0px;
        font-size: 30px;
    }
    .filtersContainer h2{
        font-size: 10px;
    }
}
@media (max-width: 600px){
    .headerWrapper h1{
        color: aqua;
        margin: 0px;
        font-size: 30px;
    }
    .filtersContainer h2{
        font-size: 10px;
    }
}
