.videogamesContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-auto-rows: minmax(30rem, auto);
    grid-gap: 1rem;
    padding: 1rem;
}

.button{
    align-self: center;
    justify-self: center;
    margin: 30px;
    color: #ffffff;
    border-color: #494ca2;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    padding: 10px;
    border-radius: 25px;
    cursor: pointer;
}

.loading {
    justify-self: center;
    width: 20%;
    margin-top: 200px;
}

@media (max-width: 1420px) {
    .videogamesContainer{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
        grid-auto-rows: minmax(26rem, auto);
        grid-gap: .8rem;
        padding: .8rem;
    }
}
@media (max-width: 900px) {
    .videogamesContainer{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
        grid-auto-rows: minmax(24rem, auto);
        grid-gap: .6rem;
        padding: .6rem;
    }
    .loading{
        width: 50%;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
@media (max-width: 600px) {
    .videogamesContainer{
        display: grid;
        grid-gap: .6rem;
        padding: .6rem;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(24rem, auto)
    }
}
@media (max-width: 300px) {
    .videogamesContainer{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: .4rem;
        padding: .4rem;
        grid-auto-rows: minmax(18rem, auto)
    }
}