.asideWrapper{
    background-color: rgba(11, 12, 12, 0.574);
    text-align: left;
    height: 100%;
    padding-top: 15px;
}

.landingPage{
    text-decoration: none;
    color: rgba(6, 207, 36, 0.908);
    font-size: 30px;
    cursor: pointer;
    font-weight: bold;
    padding-left: 25px;
}
.landingPage:hover{
    color: rgba(7, 215, 0, 0.658);
}

.asideWrapper h1,
.asideWrapper h2,
.asideWrapper h3{
    text-align: center;
}

.asideWrapper div ul li a{
    text-decoration: none;
    color: rgb(69, 249, 255);
}
.asideWrapper div ul li a:hover{
    cursor: pointer;
    color: rgba(69, 249, 255, 0.815);
}

.asideWrapper div ul li {
    text-decoration: none;
    list-style: none;
    margin-bottom: 2px;
    margin-top: 6px;
}

.filters {
    display: flex;
    align-content: space-around;
}

.showers {
    color: #0178D7;
} 

.showers:hover {
    cursor: pointer;
    color: #0177d7c9;
}

@media (max-width: 900px){
    .asideWrapper{
        height: auto;
        justify-content: center;
        text-align: center;
    }
    ul {
        margin: 0px;
        padding: 0px;
    }
    .filters {
        justify-self:center;
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 400px) {
    .asideWrapper{
    }
}