.cardContainer {
    border: 4px solid var(--border-color);
    background-color:var(--background-color);
    border-radius: 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50% 1fr;
}

.imageContainer{
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}

.cardInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

@media (max-width: 600px) {
    h1{
        font-size: 25px
    }
    h2, h3, h4 {
        font-size: 15px;
    }
}
@media (max-width: 300px) {
    h1{
        font-size: 20px
    }
    h2, h3, h4 {
        font-size: 10px;
    }
}