.nav {
    display: grid;
    grid-template-columns: 200px 4fr 1fr;
    padding: 20px;
    height: 95px;
    background-color: var(--background-color);
}
.iconContainer{
    background-image: url("../../images/VideogamesHub.webp");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
}

.searchbarContainer{
    width: 100%;
    height: 100%;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: start;
    align-items: center;
}
.searchbarForm{
    width: 96%;
}

.searchbar {
    padding: 8px;
    font-size: 15px;
    border-radius: 40px;
    width: 100%;
    transition: all .2s;
}

.searchbar:focus{
    transition: all .2s;
    background-color: cornsilk;
    color:black;
}

.aboutWrapper{
    display:grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}
 
.about{
    color: #ffffff;
    border-color: #494ca2;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    width: 80%;
}

.linkedin{
    color: #ffffff;
    border-color: #494ca2;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    width: 80%;
}

@media (max-width: 1200px) {
    .searchbarForm{
        width: 94%;
    }
    .about, .linkedin {
        font-size: 12px;
        padding: 12px 10px;
    }
}
@media (max-width: 900px) {
    .searchbarForm{
        width: 90%;
    }    
    .aboutWrapper{
        grid-gap: 10px;
    }
    .about, .linkedin {
        font-size: 11px;
        padding: 10px 8px;
    }
     
}


@media (max-width: 600px) {
    .nav{
        padding: 5px;
        grid-template-columns: 100px 3fr 2fr;
        grid-template-rows: 1fr;
    }
    .searchbarForm{
        width: 80%;
    }
    .about, .linkedin {
        font-size: 10px;
        padding: 8px 6px;
    }
}
@media (max-width: 300px) {
    .nav{
        padding: 5px;
        grid-template-columns: 3fr 3fr 1fr;
    }
}