.pageWrapper {
    display:grid;
    grid-template-columns: 225px 1fr;
}


@media (max-width: 600px) {
    .pageWrapper{
        display:grid;
        grid-template-columns: 100%;
    }
}