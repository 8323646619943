.background {
    background-image: url("../../images/RestingGif.gif");
    background-position:bottom;
    background-color: #464646;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100%;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-content: center;
}

.container {
    color: rgba(243, 207, 62, 0.932);
    display:flex;
    justify-content: center;
}

.container h1 {
    font-size: 3.5vh;
    position: absolute;
    top: 15%;
}

.fire{
    position: absolute;
    top: 36%;
    width: 11%;
    height: 42%;
}

.fire:hover{
    cursor: pointer;
}