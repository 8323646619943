.cardContainer {
    width: 100%;
    height: 100%;
    border: 3px solid var(--border-color);
    background-color:var(--background-color);
    color: var(--rajah);
    border-radius: 15px;
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
}

.imageContainer{
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
}

.infoContainer{
    display:flex;
    flex-direction: column;
}