.wrapper{
    height: 100vh;
}
.title{
    background-color: rgba(11, 12, 12, 0.574);
    padding-top: 30px;
    padding-bottom: 30px;
}

.container{
    margin-left: 25%;
    margin-right: 25%;
    padding: 25px;
    margin-bottom: 25px;
    background-color: rgba(11, 12, 12, 0.574);
    display: grid;
    align-items: center;
}

.text {
    margin: 0px;
    color: rgb(255, 236, 128);
}

.text a{    
    color: rgb(255, 226, 61);
    text-decoration: none;
}

@media (max-width: 900px) {
    .wrapper{
        height: 100%;
    }
    .container{
        margin: 0px;
        padding: 40px;
        padding-top: 0px;
        background-color: rgba(11, 12, 12, 0.574);
    }
}