body {
  background-color: #151515;
  background-image: url('./images/protruding-squares.svg');
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--rajah);
  margin: 0px;
}

:root {
  --border-color: rgb(54, 54, 54);

  --background-color: #151515;

  --background-color-faded: #222222ea;
  
  --eerie-black: #141414ff;

  --jet: #373737ff;

  --white: #ffffffff;

  --rajah: #faa549ff;

  --tart-orange: #f2564fff;

  --light-steel-blue: #b7d0f1ff; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary{
    background: #494ca2;
    color: #fff;
    border-radius: 40px;
    padding: 11px 16px;
    cursor: pointer;
    box-shadow: none !important;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden !important;
    text-transform: uppercase;
    border: none;
}

.btn-primary:hover{
  background: #31347c;
  transition: .2s all;
}